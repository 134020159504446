<template>
  <div class="banner">

    <!--        <router-link to="/customer_center" tag="div" style="margin-top: 10px">-->
    <!--            <a href="javascript:void(0)" target="_self">-->
    <!--                <img alt="" src="../assets/images/banner/ptn/kakao.jpg" style="width: 100%">-->
    <!--            </a>-->
    <!--        </router-link>-->
    <div style="margin-top: 10px">
      <router-link tag="div" to="/livetv" style="cursor: pointer">
        <img alt="" src="../assets/images/banner/ptn/live.gif" style="width: 100%">
      </router-link>
    </div>

    <div style="margin-top:10px">
      <a href="https://t.me/nj1004" target="_blank">
        <img alt="" src="../assets/images/banner/ptn/tel.gif" style="width: 100%">
      </a>
    </div>
    <div style="margin-top:10px">
      <a href="https://닌자주소.com" target="_blank">
        <img alt="" src="../assets/images/banner/ptn/www.gif" style="width: 100%">
      </a>
    </div>
    <div style="margin-top:10px">
      <a href="https://www.rotowire.com/" target="_blank">
        <img alt="" src="../assets/images/banner/ptn/lineup.gif" style="width: 100%">
      </a>
    </div>


  </div>
</template>

<script>
export default {
  name: "RightBarBannerComp"
}
</script>

<style scoped>

.banner {
  width: 100%;
  padding: 1px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
</style>