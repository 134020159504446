<template>
    <div class="promobox" v-if="$store.state.isLogin">
        <div @click="coffetimevent">
            <img src="../assets/images/banner/promo/coffe.gif" alt="">
        </div>
        <div @click="owlevent">
            <img src="../assets/images/banner/promo/owl.gif" alt="">
        </div>
        <div @click="daypayback">
            <img src="../assets/images/banner/promo/daypayback.jpg" alt="">
        </div>
        <div @click="weekpayback">
            <img src="../assets/images/banner/promo/weekpayback.jpg" alt="">
        </div>
    </div>
</template>

<script>
    import {INIT_USERINFO, RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../store/mutation-types";
    import {
        coffetimevent, daypayback,
        logout,
        owlevent,
        payback,
        payback4referrer,
        run369,
        weekpayback
    } from "../network/userRequest";

    export default {
        name: "PromoImagesComp",
        methods:{
            daypayback() {
                this.$store.commit(RECEIVE_SHOW_LOADING)
                daypayback().then(res => {
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                    if (res.data.success) {
                        this.$store.dispatch('actionUserInfo')
                        this.$swal({
                            title: '당일 페이백 지급성공',
                            type: 'success',
                            confirmButtonText: '확인',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            confirmButtonText: '확인',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                })
            },
            weekpayback() {
                this.$store.commit(RECEIVE_SHOW_LOADING)
                weekpayback().then(res => {
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                    if (res.data.success) {
                        this.$store.dispatch('actionUserInfo')
                        this.$swal({
                            title: '주간 페이백 지급 성공',
                            type: 'success',
                            confirmButtonText: '확인',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            confirmButtonText: '확인',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                })
            },
            coffetimevent() {
                this.$store.commit(RECEIVE_SHOW_LOADING);
                coffetimevent().then(res => {
                    if (res.data.success) {
                        this.$store.dispatch('actionUserInfo')
                        this.$swal({
                            title: '깜짝이벤트 ' + res.data.msg + '포인트 지급 완료',
                            type: 'success',
                            confirmButtonText: '확인',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            confirmButtonText: '확인',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                    this.$store.commit(RECEIVE_HIDE_LOADING);
                })
            },
            owlevent() {
                this.$store.commit(RECEIVE_SHOW_LOADING);
                owlevent().then(res => {
                    if (res.data.success) {
                        this.$store.dispatch('actionUserInfo')
                        this.$swal({
                            title: res.data.msg + '포인트 지급 완료\r\n\t\r\n\t' +
                                '굿모닝 이벤트란 규정 필수 숙지 후 이용바랍니다.\n',
                            type: 'success',
                            confirmButtonText: '확인',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            confirmButtonText: '확인',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                    this.$store.commit(RECEIVE_HIDE_LOADING);
                })
            },
            run369() {
                this.$store.commit(RECEIVE_SHOW_LOADING);
                run369().then(res => {
                    if (res.data.success) {
                        this.$store.dispatch('actionUserInfo')
                        this.$swal({
                            title: res.data.msg + 'P 지급 완료\r\n\t\r\n\t' +
                                '[중요]돌발이벤트 규정 숙지 후 이용바랍니다.\n',
                            type: 'success',
                            confirmButtonText: '확인',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            confirmButtonText: '확인',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                    this.$store.commit(RECEIVE_HIDE_LOADING);
                })
            },
        }
    }
</script>

<style scoped>
    .promobox{
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
        box-sizing: border-box;
        padding: 5px 0;
    }
    .promobox div{
        width: 49%;
        margin-bottom: 2px;
        cursor: pointer;
    }
    .promobox div img{
        width: 100%;
    }

    @media screen and (max-width: 1024px) {
        .promobox{

        }
    }
</style>